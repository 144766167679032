import * as React from "react";
import { Field } from "formik";
import "./style.scss";
import { Label } from "./styled";

interface IFormInputProps {
  label?: string;
  id: string;
  name: string;
  placeholder?: string;
  type: string;
  style?: object;
  size?: string;
  className?: string;
}

export const FormInput: React.FunctionComponent<IFormInputProps> = (props) => {
  return (
    <>
      <Label>{props.label}</Label>
      <Field
        autocomplete="off"
        size={props.size}
        id={props.id}
        style={props.style}
        type={props.type}
        placeholder={props.placeholder}
        name={props.name}
        className={`${props.className} form-input`}
      />
    </>
  );
};
