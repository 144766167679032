import React from "react";
import { Col } from "react-bootstrap";
import { Label, Required } from "../DocumentPage/style";
import { CountryInputComponent } from "../CountryInputComponent";
import { DropdownComponent } from "../DropdownComponent";
import { FormInput } from "../FormInput";
import { DateComponent } from "../DateComponent";

type Props = {
  selectedCountry: object;
  setsearchText: Function;
  setselectedCountry: any;
  searchCountry: any;
  countries: Array<String>;
  setcountries: any;
  searchText: string;
  setselectedqualification: any;
  selectedqualification: string;
  qualification: Array<String>;
  setstartMonth: Function;
  setstartYear: Function;
  setendYear: Function;
  setselectedGradingSystem: Function;
  selectedGradingSystem: string;
  setendMonth: Function;
  gradingSystem: Array<string>;
  selectedSchool: any;
  setselectedSchool: any;
  schools: any;
  setschools: any;
  searchSchool: any;
  setschoolSearch: any;
  schoolSearch: any;
  setaddSchool: any;
  addSchool: any;
};

export const EducationForm = (props: Props) => {
  const schoolInstitute =
    props.selectedqualification === "Higher Secondary School" ||
    props.selectedqualification === "Secondary School"
      ? "School"
      : "Institution";

  return (
    <>
      <Col md={6} style={{ marginBottom: 10 }}>
        <Label>
          <Required>*</Required> Country of Education
        </Label>
        <CountryInputComponent
          selectedCountry={props.selectedCountry}
          removeContry={() => props.setselectedCountry("")}
          setsearchText={props.setsearchText}
          setselectedCountry={props.setselectedCountry}
          arabic={false}
          searchCountry={props.searchCountry}
          countries={props.countries}
          setcountries={props.setcountries}
          searchText={props.searchText}
          placeholder={""}
        />
      </Col>
      <Col md={6} style={{ marginBottom: 20 }}>
        <Label>
          <Required>*</Required> Qualification
        </Label>
        <DropdownComponent
          children={props.qualification}
          onClick={(a: string) => {
            props.setselectedqualification(a);
          }}
          className={""}
          style={undefined}
          strArr
          selected={props.selectedqualification}
          placeholder=""
        />
      </Col>
      {props.selectedqualification === "Higher Secondary School" ||
      props.selectedqualification === "Secondary School" ? null : (
        <Col md={6} style={{ marginBottom: 20 }}>
          <Label>
            <Required>*</Required> Discipline
          </Label>
          <FormInput
            id="discipline"
            style={{ borderRadius: 3, marginBottom: 0 }}
            name="discipline"
            placeholder="Discipline"
            type="text"
          />
        </Col>
      )}
      {props.selectedqualification === "Higher Secondary School" ||
      props.selectedqualification === "Secondary School" ? (
        <Col md={6} style={{ marginBottom: 20 }}>
          <Label>
            <Required>*</Required> {schoolInstitute}
          </Label>

          <CountryInputComponent
            selectedCountry={props.selectedSchool}
            setsearchText={props.setschoolSearch}
            setselectedCountry={props.setselectedSchool}
            removeContry={() => props.setselectedSchool("")}
            arabic={false}
            setaddSchool={props.setaddSchool}
            addSchool={props.addSchool}
            searchCountry={props.searchSchool}
            schoolDd
            countries={props.schools}
            setcountries={props.setschools}
            searchText={props.schoolSearch}
            placeholder={""}
          />
        </Col>
      ) : (
        <Col md={6} style={{ marginBottom: 20 }}>
          <Label>
            <Required>*</Required> {schoolInstitute}
          </Label>
          <FormInput
            id="institute"
            style={{ borderRadius: 3, marginBottom: 0 }}
            name="institute"
            placeholder={schoolInstitute}
            type="text"
          />
        </Col>
      )}
      <Col md={6} style={{ marginBottom: 20 }}>
        <DateComponent
          onMonthFromChild={(data: React.SetStateAction<string>) =>
            props.setstartMonth(data)
          }
          onYearFromChild={(data: React.SetStateAction<string>) =>
            props.setstartYear(data)
          }
          noDate
          label={"Start year and month"}
          monthLabel={"Start Month"}
          yearLabel={"Start Year"}
        />
      </Col>
      <Col md={6} style={{ marginBottom: 20 }}>
        <DateComponent
          monthLabel={"End Month"}
          yearLabel={"End Year"}
          onYearFromChild={(data: React.SetStateAction<string>) =>
            props.setendYear(data)
          }
          onMonthFromChild={(data: React.SetStateAction<string>) =>
            props.setendMonth(data)
          }
          noDate
          label={"End year and month"}
        />
      </Col>
      <Col md={6} style={{ marginBottom: 20 }}>
        <div className="d-flex justify-content-between">
          <div className="col-md-6" style={{ paddingRight: 10 }}>
            <Label>
              <Required>*</Required> Grading System
            </Label>
            <DropdownComponent
              children={props.gradingSystem}
              onClick={(a: string) => {
                props.setselectedGradingSystem(a);
              }}
              className={""}
              style={undefined}
              strArr
              selected={props.selectedGradingSystem}
              placeholder=""
            />
          </div>

          <div className="col-md-6">
            <Label>
              <Required>*</Required> Score
            </Label>
            <FormInput
              id="score"
              style={{ borderRadius: 3, marginBottom: 0 }}
              name="score"
              placeholder="Score"
              type="text"
            />
          </div>
        </div>
      </Col>
    </>
  );
};
