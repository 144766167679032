import React from "react";
import { Form } from "react-bootstrap";
import { useField } from "formik";
import "./style.scss";

type Props = {
  label?: string;
  id: string;
  name: string;
  placeholder: string;
  type: string;
  style?: object;
  size?: string;
  className?: string;
  rows?: any;
  inputClassName?: string;
};

export const TextArea = (props: Props) => {
  const [field] = useField(props.name);

  return (
    <Form.Group className={props.className}>
      {props.label ? <Form.Label>{props.label}</Form.Label> : null}
      <Form.Control
        rows={props.rows}
        {...field}
        type={props.type}
        className={`textarea-input ${props.inputClassName}`}
        placeholder={props.placeholder}
        style={{
          overflow: "hidden",
          ...props.style,
        }}
        // maxLength={400}
        name={props.name}
        as="textarea"
      />
    </Form.Group>
  );
};
