import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import {
  addDays,
  format,
  getMonth,
  getYear,
  startOfMonth,
  endOfMonth,
  subMonths,
} from "date-fns";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";

const DateFilterContainer = styled.div``;

const DatePickersContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CustomDatePicker = styled(DatePicker)`
  margin: 10px;
  padding: 10px;
  width: 300px;
  border: 1px solid red;
  border-radius: 5px;
  background-color: #fff;
  padding: 20px !important;
  .react-datepicker {
    border: none !important;
  }
`;

const SelectButton = styled(Button)`
  background-color: #6c63ff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  align-self: center;
  &:hover {
    background-color: #5a52d4;
  }
`;

const RemoveFilterButton = styled(Button)`
  background-color: #ff6c6c;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  align-self: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #d45a5a;
  }
`;

const CustomDropdownMenu = styled(Dropdown.Menu)`
  width: max-content;
  padding: 20px !important;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const RemoveFilterContainer = styled.div`
  margin-top: 20px;
  text-align: center;
`;

type DateFilterProps = {
  startDate: Date;
  setStartDate: any;
  endDate: Date;
  setEndDate: any;
  reload: any;
};

export const DateFilter: React.FC<DateFilterProps> = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  reload,
}) => {
  const [dropdownTitle, setDropdownTitle] = useState("Date Filter");

  const handleDateRangeClick = (days: number) => {
    const today = new Date();
    const start = addDays(today, -days);
    setStartDate(start);
    setEndDate(today);
    reload(start, today);
    setDropdownTitle(
      `${format(start, "MM/dd/yyyy")} - ${format(today, "MM/dd/yyyy")}`
    );
  };

  const handleYesterdayClick = () => {
    const yesterday = addDays(new Date(), -1);
    setStartDate(yesterday);
    setEndDate(yesterday);
    reload(yesterday, yesterday);
    setDropdownTitle(format(yesterday, "MM/dd/yyyy"));
  };

  const handleTodayClick = () => {
    const today = new Date();
    setStartDate(today);
    setEndDate(today);
    reload(today, today);
    setDropdownTitle(format(today, "MM/dd/yyyy"));
  };

  const handleThisMonthClick = () => {
    const start = startOfMonth(new Date());
    const end = endOfMonth(new Date());
    setStartDate(start);
    setEndDate(end);
    reload(start, end);
    setDropdownTitle(
      `${format(start, "MM/dd/yyyy")} - ${format(end, "MM/dd/yyyy")}`
    );
  };

  const handleLastMonthClick = () => {
    const start = startOfMonth(subMonths(new Date(), 1));
    const end = endOfMonth(subMonths(new Date(), 1));
    setStartDate(start);
    setEndDate(end);
    reload(start, end);
    setDropdownTitle(
      `${format(start, "MM/dd/yyyy")} - ${format(end, "MM/dd/yyyy")}`
    );
  };

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      reload(start, end);
      setDropdownTitle(
        `${format(start, "MM/dd/yyyy")} - ${format(end, "MM/dd/yyyy")}`
      );
    }
  };

  const handleRemoveFilter = () => {
    const start = startOfMonth(new Date());
    const end = endOfMonth(new Date());
    setStartDate(start);
    setEndDate(end);
    reload(start, end);
    setDropdownTitle(`This Month`);
  };

  const isDateInCurrentMonth = (date: Date) => {
    const currentMonth = getMonth(new Date());
    const currentYear = getYear(new Date());
    return getMonth(date) === currentMonth && getYear(date) === currentYear;
  };

  const isDateInNextMonth = (date: Date) => {
    const nextMonth = (getMonth(new Date()) + 1) % 12;
    const currentYear = getYear(new Date());
    const nextMonthYear = nextMonth === 0 ? currentYear + 1 : currentYear;
    return getMonth(date) === nextMonth && getYear(date) === nextMonthYear;
  };

  return (
    <DateFilterContainer>
      <Dropdown>
        <Dropdown.Toggle
          className="filter-dd"
          variant="success"
          id="dropdown-basic"
        >
          {dropdownTitle}
        </Dropdown.Toggle>

        <CustomDropdownMenu>
          <DatePickersContainer>
            <div>
              <Dropdown.Item onClick={handleTodayClick}>Today</Dropdown.Item>
              <Dropdown.Item onClick={handleYesterdayClick}>
                Yesterday
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDateRangeClick(7)}>
                Last 7 days
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDateRangeClick(30)}>
                Last 30 days
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDateRangeClick(90)}>
                Last 90 days
              </Dropdown.Item>
              <Dropdown.Item onClick={handleThisMonthClick}>
                This Month
              </Dropdown.Item>
              <Dropdown.Item onClick={handleLastMonthClick}>
                Last Month
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDateRangeClick(365)}>
                Last year
              </Dropdown.Item>
              <Dropdown.Item
                onClick={handleRemoveFilter}
                className="remove-filter"
              >
                Remove Filter
              </Dropdown.Item>
            </div>
            <div className="d-flex flex-row">
              <CustomDatePicker
                selected={startDate}
                onChange={handleDateChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
                showMonthDropdown
                showYearDropdown
                renderDayContents={(day: any, date: any) => (
                  <div
                    className={
                      isDateInCurrentMonth(date) || isDateInNextMonth(date)
                        ? ""
                        : "text-muted"
                    }
                  >
                    {day}
                  </div>
                )}
              />
            </div>
          </DatePickersContainer>
        </CustomDropdownMenu>
      </Dropdown>
    </DateFilterContainer>
  );
};
