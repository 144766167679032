import { debounce } from "debounce";
import { Field, Form, Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { searchCountries } from "../../Services/Function";
import { ButtonComponent } from "../ButtonComponent";
import { CountryInputComponent } from "../CountryInputComponent";
import { FormInput } from "../FormInput";
import { Label } from "./style";
// import { Label } from "./style";

type Props = {
  show: boolean;
  handleClose: any;
  courseLevels: any;
};

function DocumentModal(props: Props) {
  const [selectedCountry, setselectedCountry] = useState("");
  const [searchText, setsearchText] = useState("");
  const [countries, setcountries] = useState([]);

  const searchCountry = debounce(async (e: any) => {
    if (!e.target.value) {
      setcountries([]);
    } else {
      const res = await searchCountries({
        text: e.target.value,
      });
      setcountries(res?.results);
    }
  }, 500);

  return (
    <div className="row container d-flex justify-content-center">
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
        id="modal-right"
        className="modal fade custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">Add Documents</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{}}
            onSubmit={async (
              values: any,
              { setSubmitting }: FormikHelpers<any>
            ) => {
              // setloading(true);
              // await login(values.email, values.password);
              // setloading(false);
            }}
          >
            <Form style={{ width: "70%" }} className="d-flex flex-column">
              <Row>
                <CountryInputComponent
                  selectedCountry={selectedCountry}
                  setsearchText={setsearchText}
                  setselectedCountry={setselectedCountry}
                  arabic={false}
                  searchCountry={searchCountry}
                  countries={countries}
                  setcountries={setcountries}
                  searchText={searchText}
                  country={false}
                  placeholder={"Country of Education"}
                />
                <Label> Course Level</Label>

                <Field className="form-input" as="select" name="courseLevel">
                  {props.courseLevels.map((a: any, key: any) => (
                    <option key={key} value={a.id}>
                      {a.en_name}
                    </option>
                  ))}
                </Field>
                <Col md={12} style={{ marginBottom: 32 }}>
                  <FormInput
                    id="score"
                    style={{ borderRadius: 3, marginBottom: 0 }}
                    name="score"
                    placeholder="IELTS Score"
                    type="text"
                  />
                </Col>
              </Row>
            </Form>
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          {/* <ButtonComponent
          onClick={() => {
         
            props.handleClose();
          }}
          btnType="light"
          loading={false}
          label="Close"
        /> */}
          <ButtonComponent
            onClick={() => {}}
            btnType="dark"
            loading={false}
            label="Apply"
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DocumentModal;
