import * as React from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
// import { Button } from "react-bootstrap";
import { ButtonComponent } from "../ButtonComponent";
import { DropdownComponent } from "../DropdownComponent";
import { debounce } from "debounce";
import { searchCountries } from "../../Services/Function";
import { CountryInputComponent } from "../CountryInputComponent";
import DatePicker from "react-datepicker";
import { Col } from "react-bootstrap";

interface IModalComponentProps {
  show: boolean;
  handleClose: any;
  applyFilter?: any;
  selectedDegree?: any;
  setselectedDegree?: Function;
  setselectedCountry?: Function;
  selectedCountry: any;
  selectedResidence: any;
  setselectedResidence: Function;
  setselectedStatus: Function;
  selectedStatus: any;
  selectedAd: string;
  setselectedAd: Function;
  adBudget?: any;
  setadBudget?: any;
  startDate?: any;
  setstartDate?: any;
  endDate?: any;
  setendDate?: any;
  selectedSource: any;
  setselectedSource: any;
}

const ModalComponent: React.FunctionComponent<IModalComponentProps> = (
  props
) => {
  const [countries, setcountries] = React.useState([]);
  const [residence, setresidence] = React.useState([]);
  const [searchText, setsearchText] = React.useState("");
  const [searchText1, setsearchText1] = React.useState("");

  const {
    selectedDegree,
    setselectedDegree,
    selectedCountry,
    setselectedCountry,
    setselectedResidence,
    selectedResidence,
    setselectedStatus,
    selectedStatus,
  } = props;

  const arabic = false;
  const searchCountry = debounce(async (e: any) => {
    if (!e.target.value) {
      setcountries([]);
    } else {
      const res = await searchCountries({
        text: e.target.value,
      });
      setcountries(res?.results);
    }
  }, 500);

  const searchResidence = debounce(async (e: any) => {
    if (!e.target.value) {
      setresidence([]);
    } else {
      const res = await searchCountries({
        text: e.target.value,
      });
      setresidence(res?.results);
    }
  }, 500);

  return (
    <div className="row container d-flex justify-content-center">
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
        id="modal-right"
        className="modal fade custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">Filter Students</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DropdownComponent
            children={[
              {
                en_name: "Bachelor's",
                ar_name: "البكلوريوس",
              },
              {
                en_name: "Master's",
                ar_name: "الماجستير",
              },
              {
                en_name: "PhD",
                ar_name: "الدكتوراة",
              },
            ]}
            onClick={setselectedDegree}
            className={""}
            style={{ marginTop: 10 }}
            placeholder="Degree"
            selected={selectedDegree?.en_name}
          />

          <DropdownComponent
            children={[
              {
                en_name: "New",
              },
              {
                en_name: "Sent Whatsapp",
              },
              {
                en_name: "Applied",
              },
            ]}
            onClick={setselectedStatus}
            className={""}
            style={{ marginTop: 10 }}
            placeholder="Lead Status"
            selected={selectedStatus?.en_name}
          />

          <div
            className="d-flex w-100 justify-content-center"
            style={{ marginTop: 10 }}
          >
            <CountryInputComponent
              selectedCountry={selectedCountry}
              setsearchText={setsearchText}
              setselectedCountry={setselectedCountry}
              arabic={arabic}
              searchCountry={searchCountry}
              countries={countries}
              setcountries={setcountries}
              searchText={searchText}
              placeholder={"Select country"}
            />
          </div>
          <div
            className="d-flex w-100 justify-content-center"
            style={{ marginTop: 10 }}
          >
            <input
              style={{ width: "100% !important" }}
              className="form-input-100 w-100"
              placeholder="Ad Source"
              value={props.selectedAd}
              onChange={(e) => props.setselectedAd(e.target.value)}
            />
          </div>
          <div className="d-flex w-100 justify-content-center">
            <input
              style={{ width: "100% !important" }}
              className="form-input-100 w-100"
              type="number"
              placeholder="Ad Budget"
              value={props.adBudget}
              onChange={(e) => props.setadBudget(e.target.value)}
            />
          </div>
          <DropdownComponent
            children={[
              {
                en_name: "Uapply",
              },

              {
                en_name: "Ulearn",
              },
            ]}
            onClick={props.setselectedSource}
            className={""}
            style={{ marginBottom: 10 }}
            placeholder="Source"
            selected={props.selectedSource?.en_name}
          />
          <div>
            <CountryInputComponent
              selectedCountry={selectedResidence}
              setsearchText={setsearchText1}
              setselectedCountry={setselectedResidence}
              arabic={arabic}
              searchCountry={searchResidence}
              countries={residence}
              setcountries={setresidence}
              searchText={searchText1}
              placeholder={"Select residence"}
            />
          </div>
          <div className="row">
            <Col md={6}>
              <DatePicker
                selected={props.startDate}
                onChange={(date: any) => props.setstartDate(date)}
                startDate={props.startDate}
                endDate={props.endDate}
                className="form-input"
              />
            </Col>
            <Col md={6}>
              <DatePicker
                selected={props.endDate}
                className="form-input"
                onChange={async (date: any) => {
                  props.setendDate(date);
                }}
                selectsEnd
                startDate={props.startDate}
                endDate={props.endDate}
                minDate={props.startDate}
              />
            </Col>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <ButtonComponent
            onClick={() => {
           
              props.handleClose();
            }}
            btnType="light"
            loading={false}
            label="Close"
          /> */}
          <ButtonComponent
            onClick={() => {
              props.applyFilter();
              props.handleClose();
            }}
            btnType="dark"
            loading={false}
            label="Apply"
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalComponent;
