/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { TBody, THead, Table, Td, Th, Tr } from "./styled";
import { Loader } from "../Loader/Loader";
import {
  createQuotes,
  deleteQuotes,
  updateQuotes,
} from "../../Services/Function";
import { TextArea } from "../TextArea";
import { Form, Formik, FormikHelpers } from "formik";
import { ButtonComponent } from "../ButtonComponent";

type Props = {
  quotes: any;
  reload: any;
};

export const QuotesTable = (props: Props) => {
  const { quotes, reload } = props;

  const [loading, setloading] = useState(false);

  return (
    <div className="table-responsive">
      <div>
        <Formik
          initialValues={{}}
          onSubmit={async (
            values: any,
            { setSubmitting }: FormikHelpers<any>
          ) => {
            setloading(true);
            await createQuotes({
              text: values.quote,
              visibility: true,
            });
            reload();
            values.quote = "";
            setloading(false);
          }}
        >
          <Form style={{ marginBottom: 30 }}>
            <div className="d-flex flex-row align-items-end">
              <TextArea
                id={""}
                rows={1}
                style={{ margin: 0 }}
                name={"quote"}
                placeholder={"Quotes..."}
                type={"text"}
              />
              <ButtonComponent
                style={{
                  marginLeft: 10,
                  width: "max-content",
                  fontSize: 35,
                  padding: 5,
                  fontWeight: "bold",
                }}
                className="d-flex align-items-center"
                btnType="dark"
                loading={loading}
                label="+"
              />
            </div>
          </Form>
        </Formik>
      </div>
      <Table className="table table-scroll ">
        <THead>
          <Tr>
            {/* <Th scope="col">Select</Th> */}

            <Th scope="col">Quotes</Th>
          </Tr>
        </THead>
        <React.Suspense fallback={<Loader />}>
          <TBody>
            {quotes?.map((z: any, key: any) => (
              <Tr key={key}>
                <Td style={{ opacity: z.visibility ? 1 : 0.5 }}>
                  <div className="d-flex justify-content-between">
                    {z.text}
                    <div className="d-flex flex-row align-items-center justify-content-center">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={async () => {
                          setloading(true);
                          await updateQuotes(z.id, {
                            visibility: !z.visibility,
                          });
                          reload();
                          setloading(true);
                        }}
                      >
                        {!z.visibility ? (
                          <img
                            alt="icon"
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABgUlEQVR4nO3UTUtVURTG8R9aZChBIWmhUEEQac4Swm/QsEIQ7yCivoQTm0Wz6AvUQDEbhaQYBUJB83IQvgwcqIMgiAp0ICob1onNGeS53gsS+Ic9OHuv9TxnrbP24ZgGuYQxvMc6tvAbq3iFh+g4jHAPprGDvQPWTzxGW1XxIfyK5G28xB30hkh64+t4hIXM6BvOVDG4FwmvcbFC/C18jdZ1Va2iXX204LSj5Br6mqjXjxv5xnf8ibFslCvxPX7km5PxYeejn4elBR9CK2n+5UJUkQ6eNGDwLDSSVnf5cDib6adorUP4JJ5n+bfLAeexWLqhC3GhDuImPpdy53CqCDiXiS/Grd2M5x3MoIarMevt2U2exW7EbuBB1uq5ogujmXiqJHEW7yr8h4o1HTnFiBYmA6KUWhaQOIGlCHqBt1jLBJcxFW+5F1XmXMbdf/X1fiSuhFlBYVDQmf0YB9XBl0iqlfbLBmKs095EPQbj0ZrWCgaptW8wogl8wsdmCP0/7APj9XeNzVdGIQAAAABJRU5ErkJggg=="
                          />
                        ) : (
                          <img
                            alt="icon"
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABH0lEQVR4nO2UvU5CQRCFv4poxELFzkIT8TVAan9ewehDGJFefBsE30ILLbil2lzt4QEwk5xCNzOXxcJQ8CWT3MycM7uZ3buwYtnZBq6AIVAAU0Wh3KU0C7MO9NRsNidMcytPFnvAc0bjWRKvwMG85kfAl2MeAC1gQ9HWiFKdeZtR813gwzFdV2yo6+jfgYYnfgx2btSAe+ATKIG+csbI8T2QcB7MtaV636lZzjgOvKc/FygCUV310qlZztgMvON/XeAsELUrRnSnWifwnqTn4F27oWo1LVI6h1x1OX7R0BVLxV1ieo7+DdiJDM3gRxvpttQVnWDn5j0k46l4+sNT8QLsk8maRjPNaDwBbuRZmC3gQoc2VrOJvgeqmWbFEvMNriXJVcp1zQIAAAAASUVORK5CYII="
                          />
                        )}
                      </div>
                      <div
                        onClick={async () => {
                          setloading(true);
                          await deleteQuotes(z.id);
                          reload();
                          setloading(false);
                        }}
                        style={{ marginLeft: 10, cursor: "pointer" }}
                      >
                        <svg
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10 11V17"
                            stroke="#000000"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14 11V17"
                            stroke="#000000"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M4 7H20"
                            stroke="#000000"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z"
                            stroke="#000000"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
                            stroke="#000000"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </Td>
              </Tr>
            ))}
          </TBody>
        </React.Suspense>
      </Table>
    </div>
  );
};
