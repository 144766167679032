/* eslint-disable array-callback-return */
import moment from "moment-timezone";
import * as React from "react";
import { redirect, useNavigate } from "react-router-dom";
import { DotIcon } from "../../Assets";
import {
  deleteStudent,
  deleteStudentApplication,
  getCommentsByStudentid,
  getCourseLevel,
  getStudentDocById,
  getUsers,
  truncateString,
  updateStudent,
} from "../../Services/Function";
// import { DetailModal } from "../DetailModal";
import { Loader } from "../Loader/Loader";
import Dropdown from "react-bootstrap/Dropdown";
import "./style.scss";
import {
  Table,
  TBody,
  Td,
  TdEmail,
  TdText,
  Th,
  THead,
  Tr,
  UserSection,
  TdAnchor,
  QuestionIcon,
  TextContainer,
  HoverText,
  HiddenDiv,
  HoverHeader,
  HoverSubText,
  HoverDescText,
  AssignTo,
  CrossIcon,
  Avatar,
} from "./styled";
import { DeleteModal } from "../DeleteModal";
import { CommentsModal } from "../CommentsModal";
import { StudentDocModal } from "../StudentDocModal";
import { Badge } from "react-bootstrap";
import { MoveToLost } from "../MoveToLost";

// import { Form, FormCheck } from "react-bootstrap";

interface IListViewProps {
  students?: any;
  reloadStudents?: any;
  page: number;
  setselectedStudentArr: Function;
  selectedStudentArr: Array<any>;
}

export const ListView: React.FunctionComponent<IListViewProps> = (props) => {
  const navigate = useNavigate();
  const [statusMenu, setstatusMenu] = React.useState(false);
  const [showModal, setshowModal] = React.useState<any>(false);
  const [selectedStudent, setselectedStudent] = React.useState<any>({});
  const [loading, setloading] = React.useState(false);
  const [comments, setcomments] = React.useState([]);
  const [deleteModal, setdeleteModal] = React.useState(false);
  const [docModal, setdocModal] = React.useState(false);
  const [documents, setdocuments] = React.useState([]);
  const [courseLevels, setcourseLevels] = React.useState([]);
  const [users, setusers] = React.useState([]);
  const [showModal1, setshowModal1] = React.useState<any>(null);

  // const { selectedStudentArr, setselectedStudentArr } = props;
  const bg = [
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "light",
    "dark",
  ];
  React.useEffect(() => {
    async function fetchData() {
      // You can await hereby
      try {
        const cl = await getCourseLevel({
          limit: 10,
          page: 1,
        });
        const user = await getUsers();
        setusers(user.results);

        setcourseLevels(cl.results);
      } catch (error) {
        alert(error);
      }
    }
    fetchData();
  }, []);

  const reloadDocuments = async () => {
    const res = await getStudentDocById(selectedStudent.id);
    setdocuments(res.data);
  };

  const getComments = async (id: any) => {
    const res = await getCommentsByStudentid(id);

    setcomments(res.data);
  };

  const color = (value: string) => {
    return value === "Zainab"
      ? "#1849A9"
      : value === "Farah"
        ? "#05603A"
        : value === "Cassandra"
          ? "#93370D"
          : "#912018";
  };
  const background = (value: string) => {
    return value === "Zainab"
      ? "#d1e9ff"
      : value === "Farah"
        ? "#D1FADF"
        : value === "Cassandra"
          ? "#FEF0C7"
          : "#FEE4E2";
  };

  return (
    <div style={{ height: "100vh" }} className="table-responsive">
      <Table className="table table-scroll ">
        <THead>
          <Tr>
            {/* <Th scope="col">Select</Th> */}
            <Th otherInfo></Th>
            <Th info scope="col">
              Basic Details
            </Th>
            <Th otherInfo></Th>
            <Th info scope="col">
              Study preferance
            </Th>
            <Th scope="col">Status</Th>
            <Th scope="col">Documents</Th>

            <Th scope="col">Assigned</Th>
            <Th scope="col">Action</Th>
          </Tr>
        </THead>
        <React.Suspense fallback={<Loader />}>
          {loading ? (
            <div
              style={{ height: "60vh", position: "absolute" }}
              className="d-flex align-items-center w-100 justify-content-center"
            >
              <Loader width="200px" height="200px" />
            </div>
          ) : (
            <TBody>
              {props.students?.map((z: any, key: any) => (
                <Tr key={key}>
                  {/* <Td>
                    <input
                      type="checkbox"
                      id={`custom-checkbox-${key}`}
                      name={z.name}
                      value={z.name}
                      checked={selectedStudentArr[z.id]}
                      onChange={() => {
                        if (
                          selectedStudentArr.some(
                            (a: { id: string }) => a.id === z.id
                          )
                        ) {
                          const updatedData = selectedStudentArr.filter(
                            (x: { id: string }) => x.id !== z.id
                          );
                          setselectedStudentArr(updatedData);
                        } else {
                          setselectedStudentArr((oldMessages: any) => [
                            ...oldMessages,
                            z,
                          ]);
                        }
                      }}
                    />
                  </Td> */}
                  <Td otherInfo style={{ padding: 0 }}>
                    <TextContainer>
                      <HoverText className="d-flex justify-content-center align-items-center">
                        {" "}
                        <QuestionIcon />
                      </HoverText>
                      <HiddenDiv>
                        <div className="d-flex flex-column">
                          <HoverHeader>
                            {`${z.firstName} ${z.middleName} ${z.lastName}` ||
                              "MM"}
                          </HoverHeader>
                          <HoverSubText>
                            {moment(
                              new Date(
                                parseInt(z.id.toString().substring(0, 8), 16) *
                                  1000
                              )
                            )
                              .tz("Asia/Kuwait")
                              .format("DD/MM/YYYY [at] hh:mm:a")}
                          </HoverSubText>
                          <div
                            style={{ paddingBottom: 20 }}
                            className="d-flex flex-row"
                          >
                            <HoverDescText fontWeight={600}>
                              Mobile Number:{" "}
                            </HoverDescText>
                            <HoverDescText fontWeight={400}>
                              &nbsp;
                              {z.phoneNo}
                            </HoverDescText>
                          </div>

                          <div
                            style={{ paddingBottom: 20 }}
                            className="d-flex flex-row"
                          >
                            <HoverDescText fontWeight={600}>
                              Nationality:{" "}
                            </HoverDescText>
                            <HoverDescText fontWeight={400}>
                              &nbsp;
                              {z.nationality.english_name}
                            </HoverDescText>
                          </div>

                          <div
                            style={{ paddingBottom: 20 }}
                            className="d-flex flex-row"
                          >
                            <HoverDescText fontWeight={600}>
                              DOB:{" "}
                            </HoverDescText>
                            <HoverDescText fontWeight={400}>
                              &nbsp;
                              {moment(z.dob).format("DD/MM/YYYY")}
                            </HoverDescText>
                          </div>
                          <div className="d-flex flex-row">
                            <HoverDescText fontWeight={600}>
                              Important Comments:{" "}
                            </HoverDescText>
                            <HoverDescText fontWeight={400}>
                              &nbsp;
                              {""}
                              {z.importantComment}
                            </HoverDescText>
                          </div>
                        </div>
                      </HiddenDiv>
                    </TextContainer>
                  </Td>
                  <Td
                    info
                    style={{ paddingLeft: "0px !important" }}
                    className="d-flex align-items-center justify-content-end flex-row-reverse"
                  >
                    <UserSection>
                      {/* <div>
                        <AvatarCon src={z.profilePhotoUrl} alt="icon" />
                      </div> */}
                      <div
                        className="d-flex flex-column"
                        // style={{ marginLeft: 16 }}
                      >
                        <TdText
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate(`/student/about/${z.id}`)}
                        >
                          {/* {z.fullname} */}
                          {`${z.firstName} ${z.middleName} ${z.lastName}` ||
                            "MM"}
                        </TdText>
                        <TdEmail>{z.nationality.english_name}</TdEmail>
                        {/* <TdEmail
                        // target="_blank"
                        // href={`https://wa.me/${z.phoneNo.replace(
                        //   /[^A-Z0-9]/gi,
                        //   ""
                        // )}`}
                        >{`${z.phoneNo}`}</TdEmail> */}
                        {/* <TdEmail>
                          {moment(
                            new Date(
                              parseInt(z.id.toString().substring(0, 8), 16) *
                                1000
                            )
                          )
                            .tz("Asia/Kuwait")
                            .format("DD/MM/YYYY [at] hh:mm:a")}
                        </TdEmail> */}
                      </div>
                    </UserSection>
                  </Td>
                  <Td otherInfo style={{ padding: 0 }}>
                    {" "}
                    <TextContainer>
                      <HoverText className="d-flex justify-content-center align-items-center">
                        <QuestionIcon />
                      </HoverText>

                      <HiddenDiv>
                        <div className="d-flex flex-column">
                          <HoverHeader>
                            {`${z.firstName} ${z.middleName} ${z.lastName}` ||
                              "MM"}
                          </HoverHeader>
                          <HoverSubText>
                            {moment(
                              new Date(
                                parseInt(z.id.toString().substring(0, 8), 16) *
                                  1000
                              )
                            )
                              .tz("Asia/Kuwait")
                              .format("DD/MM/YYYY [at] hh:mm:a")}
                          </HoverSubText>
                          <div
                            style={{ paddingBottom: 20 }}
                            className="d-flex flex-row"
                          >
                            <HoverDescText fontWeight={600}>
                              Degree:{" "}
                            </HoverDescText>
                            <HoverDescText fontWeight={400}>
                              &nbsp;
                              {courseLevels.map((a: any) => {
                                if (z.preference?.courseLevel === a.id) {
                                  return a.en_name;
                                }
                              })}
                            </HoverDescText>
                          </div>
                          <div
                            style={{ paddingBottom: 20 }}
                            className="d-flex flex-row"
                          >
                            <HoverDescText fontWeight={600}>
                              Majors:{" "}
                            </HoverDescText>
                            <HoverDescText fontWeight={400}>
                              &nbsp;
                              {z.preference?.courseSubjectIds
                                .map((a: any) => {
                                  return a.name;
                                })
                                .join(", ") || "N/A"}
                            </HoverDescText>
                          </div>
                          <div
                            style={{ paddingBottom: 20 }}
                            className="d-flex flex-row"
                          >
                            <HoverDescText fontWeight={600}>
                              Intake:{" "}
                            </HoverDescText>
                            <HoverDescText fontWeight={400}>
                              &nbsp;
                              {""}
                              {z.preference?.intakeMonth || ""}{" "}
                              {z.preference?.intakeYear || ""}
                            </HoverDescText>
                          </div>
                          <div className="d-flex flex-row">
                            <HoverDescText fontWeight={600}>
                              Source of Funding:{" "}
                            </HoverDescText>
                            <HoverDescText fontWeight={400}>
                              &nbsp;
                              {z.preference?.fundingSource || "N/A"}
                            </HoverDescText>
                          </div>
                        </div>
                      </HiddenDiv>
                    </TextContainer>
                  </Td>
                  <Td info style={{ paddingLeft: "0px !important" }}>
                    <div className="d-flex align-items-center justify-content-end flex-row-reverse">
                      <div className="d-flex flex-column">
                        <TdText>
                          {courseLevels?.map((a: any) => {
                            if (z.preference?.courseLevel === a.id) {
                              return a.en_name;
                            }
                          })}
                        </TdText>

                        <TdEmail>
                          {truncateString(
                            z.preference?.courseSubjectIds
                              .map((a: any) => {
                                return a.name;
                              })
                              .join(", ")
                          ) || "N/A"}
                        </TdEmail>
                        {/* <TdEmail>
                          Source of Funding -{" "}
                          {z.preference?.fundingSource || "N/A"}
                        </TdEmail> */}
                      </div>
                    </div>
                  </Td>
                  <Td>
                    <Dropdown>
                      <Dropdown.Toggle
                        className="dd-custom-toggle "
                        id="dropdown-custom-components"
                      >
                        <div className="d-flex flex-column justify-content-between">
                          {z.status.length ? (
                            z.status?.map((st: string, key: any) => (
                              <div
                                className="d-flex flex-column"
                                style={{ position: "relative", marginRight: 8 }}
                                key={key}
                              >
                                <Badge
                                  pill
                                  bg={bg[key]}
                                  onClick={() => {
                                    setstatusMenu(statusMenu ? !z.id : z.id);
                                  }}
                                  style={{ marginBottom: 4, cursor: "pointer" }}
                                >
                                  {st}
                                </Badge>
                                <CrossIcon
                                  onClick={async () => {
                                    try {
                                      setloading(true);
                                      const updated = z.status.filter(
                                        (x: any) => x !== st
                                      );
                                      await updateStudent(z.id, {
                                        status: updated,
                                      });
                                      await props.reloadStudents(props.page);
                                      setloading(false);
                                    } catch (error: any) {
                                      setloading(false);
                                      alert(error.response.data.message);
                                    }
                                  }}
                                >
                                  <div style={{ marginBottom: 4 }}>x</div>
                                </CrossIcon>
                              </div>
                            ))
                          ) : (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setstatusMenu(statusMenu ? !z.id : z.id);
                              }}
                            >
                              N/A
                            </div>
                          )}
                        </div>
                      </Dropdown.Toggle>

                      {/* {showMenu === z.id ? ( */}
                      <Dropdown.Menu className={"status-menu"}>
                        {["Missing documents", "Missing ielts"].map(
                          (a: any, key: any) => (
                            <Dropdown.Item>
                              {" "}
                              <Badge
                                pill
                                className="d-flex flwx-column"
                                key={key}
                                style={{ marginBottom: 10 }}
                                onClick={async () => {
                                  try {
                                    setloading(true);
                                    if (!z.status.includes(a)) {
                                      await updateStudent(z.id, {
                                        status: [...z.status, a],
                                      });
                                      await props.reloadStudents(props.page);
                                    }
                                    setloading(false);
                                    setstatusMenu(false);
                                  } catch (error: any) {
                                    setloading(false);
                                    alert(error.response.data.message);
                                  }
                                }}
                                bg={bg[key]}
                              >
                                {a}
                              </Badge>
                            </Dropdown.Item>
                          )
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Td>
                  <Td>
                    <div className="d-flex flex-column">
                      <TdAnchor
                        onClick={async () => {
                          const resp = await getStudentDocById(z.id);
                          setdocuments(resp.data);
                          setselectedStudent(z);
                          setdocModal(true);
                        }}
                      >
                        Documents
                      </TdAnchor>
                    </div>
                  </Td>

                  <Td>
                    <TdText className="d-flex flex-row">
                      {z.assignedTo.length ? (
                        z.assignedTo.map((assignment: any, key: any) => {
                          const user: any = users.find(
                            (as: any) => as.id === assignment.user
                          );
                          return user ? (
                            <TextContainer key={key}>
                              <HoverText>
                                <AssignTo style={{ marginRight: 4 }}>
                                  {user.avatar ? (
                                    <Avatar src={user.avatar} alt="img" />
                                  ) : (
                                    <div>
                                      <div
                                        className="d-flex align-items-center justify-content-center"
                                        style={{
                                          width: 40,
                                          height: 40,
                                          borderRadius: 20,
                                          background: "#f42f2f",
                                          color: "#fff",
                                          fontWeight: 700,
                                        }}
                                      >{`${user.name[0].toUpperCase()}${user.name[1].toUpperCase()}`}</div>
                                    </div>
                                  )}
                                </AssignTo>
                              </HoverText>

                              <HiddenDiv style={{ padding: 10, top: 45 }}>
                                {user.name}
                                <br />
                                {assignment.role.toUpperCase()}
                                <br />
                              </HiddenDiv>
                            </TextContainer>
                          ) : null;
                        })
                      ) : (
                        <AssignTo
                          style={{ marginRight: 4, cursor: "auto" }}
                          color={color("")}
                          background={background("")}
                        >
                          N/A
                        </AssignTo>
                      )}
                    </TdText>
                  </Td>

                  <Td
                    style={{ position: "relative" }}
                    // onClick={() => setshowMenu(showMenu ? !z.id : z.id)}
                  >
                    <Dropdown>
                      <Dropdown.Toggle
                        className="dd-custom-toggle"
                        id="dropdown-custom-components"
                      >
                        <DotIcon style={{ cursor: "pointer" }} />
                      </Dropdown.Toggle>

                      {/* {showMenu === z.id ? ( */}
                      <Dropdown.Menu className="dd-custom-menu">
                        <Dropdown.Item
                          className="dd-custom-items"
                          onClick={async () => {
                            setshowModal(!showModal);
                            setselectedStudent(z);
                            getComments(z.id);
                          }}
                        >
                          Comments
                        </Dropdown.Item>
                        <Dropdown.Item
                          target="_blank"
                          href={`https://wa.me/${z.phoneNo.replace(
                            /[^A-Z0-9]/gi,
                            ""
                          )}?text=Hello%20${
                            z.fullname
                          }%20I%20would%20like%20more%20information%20`}
                          className="dd-custom-items"
                        >
                          Send WhatsApp
                        </Dropdown.Item>

                        <Dropdown.Item
                          target="_blank"
                          onClick={async () => {
                            setshowModal1(true);
                            setselectedStudent(z);
                          }}
                        >
                          Move to Lost
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="dd-custom-items"
                          onClick={async () => {
                            setdeleteModal(true);
                            setselectedStudent(z);
                          }}
                        >
                          Delete Student
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Td>
                </Tr>
              ))}
            </TBody>
          )}
        </React.Suspense>
      </Table>

      {/* {showModal ? (
        <DetailModal
          students={selectedStudent}
          show={showModal}
          onHide={() => setshowModal(false)}
        />
      ) : null} */}

      {showModal ? (
        <CommentsModal
          selectedStudent={selectedStudent}
          comments={comments}
          getComments={(id: any) => getComments(id)}
          show={showModal}
          handleClose={() => setshowModal(false)}
        />
      ) : null}

      {docModal ? (
        <StudentDocModal
          reloadDocuments={reloadDocuments}
          show={docModal}
          selectedStudent={selectedStudent}
          handleClose={() => setdocModal(false)}
          documents={documents}
        />
      ) : null}

      {showModal1 ? (
        <MoveToLost
          onHide={() => setshowModal1(null)}
          onClick={undefined}
          show={showModal1}
          reloadStudents={async () => await props.reloadStudents(props.page)}
          student={selectedStudent}
        />
      ) : null}

      <DeleteModal
        onClick={async () => {
          try {
            setloading(true);
            setdeleteModal(false);

            // Delete all student applications
            const deletePromises = selectedStudent.applications.map(
              (app: any) => deleteStudentApplication(app)
            );
            await Promise.all(deletePromises);

            // Check if all applications were deleted successfully
            // if (selectedStudent.applications.length === 0) {
            await deleteStudent(selectedStudent.id); // Now delete the student
            await props.reloadStudents(props.page); // Reload the student list
            setselectedStudent({});

            setloading(false);
          } catch (error: any) {
            alert(
              error.response?.data?.message ||
                "An error occurred while deleting the student."
            );
            setloading(false);
            setselectedStudent({});
          }
        }}
        selectedStudent={selectedStudent}
        onHide={() => setdeleteModal(false)}
        show={deleteModal}
      />
    </div>
  );
};
