import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { CountryInputComponent } from "../CountryInputComponent";
import { ButtonComponent } from "../ButtonComponent";
import {
  createStudent,
  getCourseLevel,
  searchCountries,
  searchSubjects,
} from "../../Services/Function";
import { debounce } from "debounce";
import { Form, Formik, FormikHelpers, Field } from "formik";
import { FormInput } from "../FormInput";
import { Label, Required } from "../AboutPage/styled";
import PhoneInput from "react-phone-number-input";
import { BorderBottom, Con, HeaderText, SubText } from "./styled";
import {
  CreateStudentIcon1,
  CreateStudentIcon2,
  CreateStudentIcon3,
  ShareIcon,
} from "../../Assets";
import { MultipleInputComponent } from "../MultipleInputComponent";

type Props = {
  show: boolean;
  handleClose: any;
  applyFilter?: any;
  selectedDegree?: any;
  setselectedDegree?: Function;
  setselectedCountry?: Function;
  selectedCountry: any;
  selectedResidence: any;
  setselectedResidence: Function;
  setselectedStatus: Function;
  selectedStatus: any;
  reloadStudents?: any;
};

const CreateModal = (props: Props) => {
  const [countries, setcountries] = useState([]);
  const [residence, setresidence] = useState([]);
  const [searchText, setsearchText] = useState("");
  const [searchText1, setsearchText1] = useState("");
  const [loading, setloading] = useState(false);
  const [phoneNo, setphoneNo] = useState("");
  const [student, setstudent] = useState<any>({});
  const [courseLevels, setcourseLevels] = React.useState([]);
  const [selectedSubject, setselectedSubject] = useState([]);
  const [subjects, setsubjects] = useState([]);
  const [errorMessage, seterrorMessage] = useState("");
  const [searchText2, setsearchText2] = useState("");
  const [studentCreated, setstudentCreated] = useState(false);
  const {
    selectedCountry,
    setselectedCountry,
    setselectedResidence,
    selectedResidence,
  } = props;

  React.useEffect(() => {
    async function fetchData() {
      try {
        const cl = await getCourseLevel({
          limit: 10,
          page: 1,
        });
        setcourseLevels(cl.results);
      } catch (error) {
        alert(error);
      }
    }
    fetchData();
  }, []);

  const arabic = false;
  const searchCountry = debounce(async (e: any) => {
    if (!e.target.value) {
      setcountries([]);
    } else {
      const res = await searchCountries({
        text: e.target.value,
      });
      setcountries(res?.results);
    }
  }, 500);
  const destinations = [
    { en: "United Kingdom", ar: "بريطانيا" },
    { en: "Other", ar: "غيرها" },
  ];
  const searchResidence = debounce(async (e: any) => {
    if (!e.target.value) {
      setresidence([]);
    } else {
      const res = await searchCountries({
        text: e.target.value,
      });
      setresidence(res?.results);
    }
  }, 500);

  const searchSub = debounce(async (e: any) => {
    if (!e.target.value) {
      setsubjects([]);
    } else {
      const res = await searchSubjects({
        text: e.target.value,
      });
      setsubjects(res?.results);
    }
  }, 500);

  const gender = [
    "Select",
    "Male",
    "Female",
    "Others",
    "Not Willing To Disclose",
  ];

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        className="modal fade"
        centered
      >
        <Formik
          initialValues={{
            firstName: "",
            middleName: "",
            lastName: "",
            email: "",
            source: "",
          }}
          onSubmit={async (
            values: any,
            { setSubmitting }: FormikHelpers<any>
          ) => {
            setloading(true);
            const courseIds = await selectedSubject.map((a: any) => {
              return { slug: a.slug || a.key, name: a.name };
            });
            const res = await createStudent({
              firstName: values.firstName,
              middleName: values.middleName,
              lastName: values.lastName,
              email: values.email,
              phoneNo: phoneNo,
              nationality: selectedCountry,
              residence: selectedResidence,
              gender: values.gender,
              source: values.source.key,
              preference: {
                studyDestinations: values.destination,
                courseLevel: values.courseLevel,
                courseSubjectIds: courseIds,
              },
            });
            setstudent(res);
            setloading(false);
            await props.reloadStudents();
            setstudentCreated(true);
          }}
        >
          <Form>
            <Modal.Header closeButton>
              <Modal.Title className="modal-title">Add student</Modal.Title>
            </Modal.Header>
            <Modal.Body
              className="h-100"
              style={{ padding: studentCreated ? 0 : 15, overflow: "scroll" }}
            >
              {studentCreated ? (
                <div>
                  <Con className="d-flex flex-column">
                    <HeaderText>You’ve added a new student!</HeaderText>
                    <SubText>
                      Get started with managing their profile by adding any of
                      the following:
                    </SubText>
                  </Con>
                  <BorderBottom />
                  <Con
                    href={`/student/about/${student?.id}`}
                    className="d-flex flex-column"
                  >
                    <div className="d-flex justify-content-between">
                      <CreateStudentIcon1 />
                      <ShareIcon />
                    </div>
                    <HeaderText>Add study preferences</HeaderText>
                    <SubText>Come on add it what are you waiting for?</SubText>
                  </Con>
                  <BorderBottom />
                  <Con className="d-flex flex-column">
                    <div className="d-flex justify-content-between">
                      <CreateStudentIcon2 />
                      <ShareIcon />
                    </div>
                    <HeaderText>Add Education Level</HeaderText>
                    <SubText>Come on add it what are you waiting for?</SubText>
                  </Con>
                  <BorderBottom />
                  <Con className="d-flex flex-column">
                    <div className="d-flex justify-content-between">
                      <CreateStudentIcon3 />
                      <ShareIcon />
                    </div>
                    <HeaderText>Add Travel Details</HeaderText>
                    <SubText>Passport, Civil ID, etc.</SubText>
                  </Con>
                  <BorderBottom />
                </div>
              ) : (
                <>
                  <FormInput
                    id="firstName"
                    name="firstName"
                    placeholder=""
                    type="text"
                    label="First Name"
                  />
                  <FormInput
                    id="middleName"
                    name="middleName"
                    placeholder=""
                    type="text"
                    label="Middle Name"
                  />
                  <FormInput
                    id="lastName"
                    name="lastName"
                    placeholder=""
                    type="text"
                    label="Last Name"
                  />

                  <FormInput
                    id="email"
                    name="email"
                    placeholder=""
                    type="email"
                    label="Email"
                  />
                  <div>
                    <Label style={{ color: "#344054", fontWeight: "normal" }}>
                      <Required>*</Required> Phone Number
                    </Label>
                    <PhoneInput
                      international
                      defaultCountry="KW"
                      value={phoneNo}
                      onChange={(e: any) => setphoneNo(e)}
                    />
                  </div>
                  <div style={{ marginTop: 24 }}>
                    <Label style={{ color: "#344054", fontWeight: "normal" }}>
                      <Required>*</Required> Gender
                    </Label>
                    <Field className="form-input" as="select" name="gender">
                      {gender.map((a) => (
                        <option value={a}>{a}</option>
                      ))}
                    </Field>
                  </div>
                  <div>
                    <Label>
                      <Required>*</Required> Study Destination
                    </Label>

                    <Field
                      className="form-input"
                      as="select"
                      name="destination"
                    >
                      <option value="" disabled selected>
                        Select an option
                      </option>
                      {destinations
                        // .filter(
                        //   (z: any) =>
                        //     z.en === student.preference?.studyDestinations
                        // )
                        .map((a, key) => (
                          <option key={key} value={a.en}>
                            {a.en}
                          </option>
                        ))}
                    </Field>
                  </div>
                  <div>
                    <Label>
                      <Required>*</Required> Course Level
                    </Label>

                    <Field
                      className="form-input"
                      as="select"
                      name="courseLevel"
                    >
                      <option value="" disabled selected>
                        Select an option
                      </option>

                      {courseLevels?.map((a: any, key: any) => (
                        <>
                          <option key={key} value={a.id}>
                            {a.en_name}
                          </option>
                        </>
                      ))}
                    </Field>
                  </div>
                  <div>
                    <Label>
                      <Required>*</Required> Subjects
                    </Label>

                    <MultipleInputComponent
                      selectedUniversity={selectedSubject}
                      setselectedUniversity={setselectedSubject}
                      setsearchText={setsearchText2}
                      arabic={false}
                      universities={subjects}
                      searchText={searchText2}
                      searchUniversity={(e: any) => searchSub(e)}
                      setuniversities={setsubjects}
                      seterrorMessage={seterrorMessage}
                      error={
                        errorMessage ? (
                          <div style={{ marginBottom: 5 }}>{errorMessage}</div>
                        ) : null
                      }
                    />
                  </div>
                  <div>
                    <Label style={{ color: "#344054", fontWeight: "normal" }}>
                      <Required>*</Required>Nationality
                    </Label>
                    <CountryInputComponent
                      selectedCountry={selectedCountry}
                      setsearchText={setsearchText}
                      setselectedCountry={setselectedCountry}
                      arabic={arabic}
                      searchCountry={searchCountry}
                      countries={countries}
                      setcountries={setcountries}
                      searchText={searchText}
                      country={false}
                      placeholder={"Select country"}
                    />
                  </div>
                  <div style={{ marginTop: 14 }}>
                    <Label style={{ color: "#344054", fontWeight: "normal" }}>
                      <Required>*</Required> Residence
                    </Label>
                    <CountryInputComponent
                      selectedCountry={selectedResidence}
                      setsearchText={setsearchText1}
                      setselectedCountry={setselectedResidence}
                      arabic={arabic}
                      searchCountry={searchResidence}
                      countries={residence}
                      setcountries={setresidence}
                      searchText={searchText1}
                      country={false}
                      placeholder={"Select residence"}
                    />
                  </div>
                  <div style={{ marginTop: 14 }}>
                    <Label>
                      <Required>*</Required> Source
                    </Label>

                    <Field className="form-input" as="select" name="source">
                      <option value="" disabled selected>
                        Select an option
                      </option>

                      {[
                        { key: "ulearn", value: "Ulearn" },
                        { key: "uapply", value: "Uapply" },
                      ]?.map((a: any, key: any) => (
                        <>
                          <option key={key} value={a.key}>
                            {a.value}
                          </option>
                        </>
                      ))}
                    </Field>
                  </div>
                </>
              )}

              {/* <ButtonComponent btnType="dark" loading={loading} label="Login" /> */}
            </Modal.Body>
            <Modal.Footer
              className="d-flex justify-content-start"
              style={{ position: "relative", bottom: 0, width: "100%" }}
            >
              {studentCreated ? (
                <ButtonComponent
                  onClick={() => {
                    props.handleClose();
                    setstudentCreated(false);
                  }}
                  btnType="dark"
                  loading={loading}
                  label="Done"
                />
              ) : (
                <>
                  <ButtonComponent
                    btnType="dark"
                    loading={loading}
                    label="Create student"
                  />
                  <ButtonComponent
                    onClick={() => {
                      props.handleClose();
                      setstudentCreated(false);
                    }}
                    btnType="light"
                    loading={loading}
                    label="Close"
                  />
                </>
              )}
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </div>
  );
};

export default CreateModal;
