import * as React from "react";
import { CloseIcon } from "../../Assets";
import {
  BadgeCon,
  BadgeText,
  InputComponent,
  SubjectsContainer,
  Text,
} from "./styled";

interface IMultipleInputComponentProps {
  selectedUniversity: any;
  setselectedUniversity: any;
  setsearchText: any;
  arabic: boolean;
  universities: Array<any>;
  searchText: any;
  searchUniversity: any;
  setuniversities: any;
  seterrorMessage: Function;
  error: any;
  className?: string;
  filterCourses?: any;
  multiSelectClass?: string;
  coursePage?: boolean;
  conStyle?: object;
}

export const MultipleInputComponent: React.FunctionComponent<
  IMultipleInputComponentProps
> = (props) => {
  const {
    selectedUniversity,
    setselectedUniversity,
    setsearchText,
    arabic,
    universities,
    searchUniversity,
    searchText,
    setuniversities,
    seterrorMessage,
    error,
    className,
    filterCourses,
    multiSelectClass,
    coursePage,
    conStyle,
  } = props;

  const [closeMenu, setcloseMenu] = React.useState(false);

  return (
    <div className={`${multiSelectClass} multiselect-search-input`}>
      <div className="d-flex w-100 justify-content-center">
        <div
          className={`d-flex w-100 ${coursePage ? "flex-column" : "flex-row"}`}
          style={{ position: "relative", ...conStyle }}
        >
          <InputComponent
            placeholder={""}
            name="name"
            onChange={async (e: any) => {
              seterrorMessage("");
              setsearchText(e.target.value);
              await searchUniversity(e);
              setcloseMenu(true);
            }}
            type="text"
            className={`search-input ${className}`}
            autoComplete="off"
            value={searchText}
            arabic={arabic}
          />
          <>
            {universities?.length && closeMenu ? (
              <div
                className="d-flex flex-column "
                style={{
                  background: "#fff",
                  position: coursePage ? "relative" : "absolute",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  borderRadius: 8,

                  top: coursePage ? 0 : 45,
                  maxHeight: 200,
                  overflowY: "scroll",
                  width: "100%",
                  padding: "4px 0 4px 0",
                  zIndex: 99,
                }}
              >
                {universities?.map(
                  (a: { id: string; slug: string; name: string }) => (
                    <Text
                      key={a.id}
                      onClick={async () => {
                        setsearchText("");
                        setuniversities([]);
                        setcloseMenu(false);

                        if (selectedUniversity.length === 5) {
                          seterrorMessage("Error");
                        } else if (
                          selectedUniversity.some(
                            (item: any) => item.slug === a.slug
                          )
                        ) {
                        } else {
                          seterrorMessage("");
                          setuniversities([]);
                          selectedUniversity.push(a);
                          if (filterCourses) {
                            await filterCourses(selectedUniversity);
                          }

                          setcloseMenu(false);
                        }
                      }}
                      className="custom-dd-menu"
                      style={{
                        padding: "5px 15px",
                      }}
                      arabic={arabic}
                    >
                      {a.name}
                    </Text>
                  )
                )}{" "}
              </div>
            ) : searchText && !universities.length ? (
              <div
                className="d-flex flex-column "
                style={{
                  background: "#fff",
                  position: coursePage ? "relative" : "absolute",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  borderRadius: 8,
                  top: coursePage ? 0 : 40,
                  width: "100%",
                  padding: "10px 0 10px 0",
                }}
              >
                {" "}
                <div
                  className="d-flex justify-content-center"
                  style={{ color: "#969696" }}
                >
                  Loading...
                </div>
              </div>
            ) : null}
          </>
        </div>
      </div>
      {error}
      <SubjectsContainer className="d-flex flex-row flex-wrap">
        {selectedUniversity?.map((a: any) => (
          <BadgeCon>
            <BadgeText>
              {a.name.length > 20
                ? `${a.name.replace(/-/g, " ").substring(0, 20)}...`
                : a.name}
            </BadgeText>
            <CloseIcon
              onClick={async () => {
                const deleted = selectedUniversity.filter(
                  (z: any) => z.slug !== a.slug
                );
                if (filterCourses) {
                  await filterCourses(deleted);
                }
                setselectedUniversity(deleted);

                seterrorMessage("");
                // setselectedUniversity(
                //   ...selectedUniversity.filter((z: any) => z.id === a.id)
                // );
              }}
            />
          </BadgeCon>
        ))}
      </SubjectsContainer>
    </div>
    //   {errorMessage ? (
    //     <ErrorMessage>{errorMessage}</ErrorMessage>
    //   ) : null}
  );
};
